


















import {
  defineComponent, computed, PropType,
} from '@vue/composition-api';
import { getAngieUIComponent } from '~/selectors/selectorsMap';
import { storyBlokCompResp } from '~/selectors/types';
import { IGrowthBookExperiment } from './types';

export default defineComponent({
  name: 'Experiment',
  inheritAttrs: false,
  props: {
    growthbook: {
      type: Object as PropType<IGrowthBookExperiment>,
      required: false,
      default: () => ({}),
    },
    body: {
      type: Array as PropType<storyBlokCompResp[]>,
      required: false,
      default: () => ([]),
    },
    oneHundredPercentHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    oneHundredPercentWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const activeExperiment = computed(() => props?.growthbook?.selectedExperiment?.name);
    const expVariant = computed(() => props?.growthbook?.variant);

    // @ts-ignore
    const { $store } = useNuxtApp();

    const activeVariant = computed(() => {
      const exp = $store.getters.getExperimentById(activeExperiment.value);
      if (!exp) return '0';

      const isValid = validateVariant(exp?.variant);
      return isValid ? exp.variant : '0';
    });
    function validateVariant(str: string) {
      const num = parseInt(str, 10);
      return typeof str === 'string' && !Number.isNaN(num) && num >= 0 && num <= 6;
    }

    function getBindings(comp: storyBlokCompResp) {
      return getAngieUIComponent(comp.component, comp) || comp;
    }

    return {
      visible: computed(() => expVariant.value === activeVariant.value),
      container: computed(() => (props.body ?? [])),
      getBindings,
    };
  },
});



































import { StoryblokComponent } from 'storyblok-js-client';
import { computed, defineComponent } from '@vue/composition-api';
import { transformMediaBackgroundContent } from '~/selectors/selectors';
import { ANGIE_UI_COMPONENTS_MAP } from '~/selectors/selectorsMap';
import { findComponent, filterOutComponent, findAllComponents } from '~/selectors/selectorsHelpers';
import { isMobileEffect, isTabletUpEffect } from '~/effects/matchMedia';
import { getValidCSSUnit } from '~/constants/helpers';
import { IExperimentProps } from '~/components/Experiment/Experiment';

export default defineComponent({
  name: 'Hero',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isMobile = isMobileEffect();
    const isTabletUp = isTabletUpEffect();

    const getMediaBgExpiments = computed(() => {
      const experiments = findAllComponents({ name: 'Experiment', cmsArray: props.content.contentContainer });
      const expMediaBgComp = experiments.filter((exp: IExperimentProps) => exp.body.some((subComp: StoryblokComponent<string>) => subComp.component === 'MediaBackground'));

      return expMediaBgComp;
    });
    const getMediaBackgroundComponent = computed(() => findComponent({ name: 'MediaBackground', cmsArray: props.content.contentContainer }));
    const heroContainerClasses = computed(() => [
      props.content?.containerSize,
      isMobile.value && props.content.stackContentMobile ? 'stacked-mobile' : '',
      isTabletUp.value && props.content.stackContentTabletUp ? 'stacked-tablet-up' : '',
    ].filter(c => c?.length));

    return {
      getMediaBgExpiments,
      filteredComponentList: computed(() => {
        const nomedia = filterOutComponent({ name: 'MediaBackground', cmsArray: props.content.contentContainer });
        const filteredData = nomedia.filter(item => !item.body || item.body.some((subItem: StoryblokComponent<string>) => subItem.component !== 'MediaBackground'));

        return filteredData;
      }),
      heroContainerClasses,
      contentClasses: computed(() => {
        const alignment = props.content?.horizontalContentAlignment;
        const stacked = (isMobile.value && props.content.stackContentMobile) ?? (isTabletUp.value && props.content.stackContentTabletUp);

        return {
          stacked,
          absolute: !stacked,
          [alignment]: alignment != null,
        };
      }),
      getVerticalAlignment: computed(() => {
        const style = {
          top: '',
          bottom: '',
        };

        if (props.content?.verticalContentAlignment) {
          style.top = `${props.content.verticalContentAlignment}%`;
        }
        if (props.content?.verticalContentAlignmentBottom) {
          style.bottom = getValidCSSUnit(props.content.verticalContentAlignmentBottom);
          style.top = 'initial';
        }

        return style;
      }),
      getMediaBackgroundComponent,
      getMediaBackgroundProps: computed(() => transformMediaBackgroundContent(getMediaBackgroundComponent.value)),
      getProps(comp: any) {
        const angieUIComp = ANGIE_UI_COMPONENTS_MAP.get(comp.component);
        if (angieUIComp) return angieUIComp(comp);

        return comp.component ? comp : {};
      },
    };
  },
});
